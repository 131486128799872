.modal-danger .modal-content{
    border: 1px solid red;
    text-align: center;

    .modal-body{
       display: flex;
       justify-content: space-around;

        button{
           background-color: transparent;
           padding: 5px 20px;
           border: 1px solid green;
           border-radius: 10px;

           &:hover{
               background-color: green;
               color: white;
               transition: 0.5s;
           }
        }

        button:first-child{
            border: 1px solid red;

           &:hover{
                background-color: red;
                
            }
        }

    }
}

.btn-circle {
    border-radius: 30px;
}

body.authentication-bg {
    background-image: url("../images/bg-login.jpg");
}

.footer-login {
    background-color: #1f1f1f;
    width: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
    padding: 15px 0;
    border-top: 0.5px solid $green-vetscan;
    -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
    box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);

    p {
        color: $green-vetscan;
        margin-bottom: 0;

        img{
            margin-right: 10px;
            width: 20px;
        }

        button{
            margin-left: 10px;
            background-color: transparent;
            border: 1px solid $green-vetscan;
            border-radius: 20px;
            padding: 0 20px;
            color: $green-vetscan;
            font-weight: 600;

            &:hover{
                background-color: $green-vetscan;
                color: black;
                transition: 0.8s;
            }
        }
    }
}

.dowload-exam{
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: #000000a6;
    border: 1px solid white;
    cursor: pointer;
    color: white;
    font-size: 30px;
    border-radius: 50%;
    padding: 10px;
    &:hover{
        background-color: gray;
    }
}

.btn-control-carousel{
    width: 50%;
    height: 95%;
    background: none;
    margin: 5%;
    color: #3f8f8e;
    border: none;
    font-size: 50px;
    &:hover{
        background-color: #cde2e2;
        border: 1px solid #3f8f8e;
        color: #3f8f8e;
    }
    &:focus{
        background-color: transparent;
        border: 1px solid #3f8f8e;
        color: #3f8f8e;
    }
}